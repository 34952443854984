import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  closestCenter,
  DragEndEvent,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { toast } from "react-toastify";

import { serverApi } from "api";
import { ROUTES } from "constants/routes";
import { SER } from "utils/serverErrorHandler";
import { isMobile } from "utils/isMobile";
import { getDeviceDetailsRoute } from "utils/getDeviceDetailsRoute";
import { isDefined } from "utils/isDefined";
import { Header } from "components/Header";
import { Button } from "components/Button";
import { Empty } from "components/Empty";
import { ModalAddDevice } from "components/Modal/ModalAddDevice";
import { ModalConfirmation } from "components/Modal/ModalConfirmation";
import { HorizontalMenu } from "components/HorizontalMenu";
import { ItemDevice } from "components/Item/ItemDevice";
import { Loading } from "components/Loading";
import { NetworkIcon } from "icons/NetworkIcon";
import { PlusIcon } from "icons/PlusIcon";
import { EditIcon } from "icons/EditIcon";
import { RefreshIcon } from "icons/RefreshIcon";
import { CloseIcon } from "icons/CloseIcon";
import { CheckIcon } from "icons/CheckIcon";
import { DropdownIcon } from "icons/DropdownIcon";
import { Device } from "types";

type LabDevices = { id: number; name: string; devices: Device[] }[];

export const Devices: React.FC = () => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [labDevices, setLabDevices] = useState<LabDevices>([]);
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [collapsedDevicesBlock, setCollapsedDevicesBlock] = useState<number[]>(
    []
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalAddDevice, setIsModalAddDevice] = useState(false);
  const [isModalConfirmation, setIsModalConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { deviceType } = useParams();
  const { t } = useTranslation();

  const getDevices = useCallback(() => {
    SER(async () => {
      setIsLoading(true);

      const { data: machines } = await serverApi.getUserMachineList();
      const { data: devices } = await serverApi.getDevices(
        machines.data.map(({ uuid }) => uuid)
      );
      const { data: softwareMachines } = await serverApi.getSoftwareData(
        "machines"
      );
      const order = softwareMachines.data?.order || [];

      setDevices(
        devices.sort(
          (a, b) => order.indexOf(a.unitKey) - order.indexOf(b.unitKey)
        )
      );

      setIsLoading(false);
    });
  }, []);

  const getLabDevices = useCallback(() => {
    SER(async () => {
      const { data: labs } = await serverApi.getLabs();

      const labDevices = await Promise.all(
        labs.data.map(async ({ name, id }) => {
          const { data: machines } = await serverApi.getLabMachineList(id);

          if (machines.data.length) {
            const { data: devices } = await serverApi.getDevices(
              machines.data.map(({ uuid }) => uuid)
            );

            return { id, name, devices };
          }
        })
      );

      setLabDevices(labDevices.filter(isDefined));
    });
  }, []);

  useEffect(() => {
    getDevices();
    getLabDevices();
  }, [getDevices, getLabDevices]);

  const sortableItems = useMemo(
    () => devices.map((device) => device.unitKey),
    [devices]
  );

  const showModalAddDevice = useCallback(() => {
    setIsModalAddDevice(true);
  }, []);

  const hideModalAddDevice = useCallback(() => {
    setIsModalAddDevice(false);
    getDevices();
  }, [getDevices]);

  const showModalConfirmation = useCallback(() => {
    setIsModalConfirmation(true);
  }, []);

  const hideModalConfirmation = useCallback(() => {
    setIsModalConfirmation(false);
  }, []);

  const enableEditMode = useCallback(() => {
    setIsEditMode(true);
  }, []);

  const disableEditMode = useCallback(() => {
    setSelectedDevices([]);
    setIsEditMode(false);

    SER(async () => {
      await serverApi.setSoftwareData("machines", {
        order: sortableItems,
      });
    });
  }, [sortableItems]);

  const toDeviceDetails = useCallback(
    (device: Device) => {
      navigate(getDeviceDetailsRoute(device), { state: device });
    },
    [navigate]
  );

  const selectDevice = useCallback(
    (unitKey: string) => {
      if (selectedDevices.includes(unitKey)) {
        setSelectedDevices(selectedDevices.filter((item) => item !== unitKey));
      } else {
        setSelectedDevices([...selectedDevices, unitKey]);
      }
    },
    [selectedDevices]
  );

  const removeSelectedDevices = useCallback(() => {
    SER(async () => {
      await Promise.all(
        selectedDevices.map((unitKey) => serverApi.removeUserMachine(unitKey))
      );
      hideModalConfirmation();
      setSelectedDevices([]);
      getDevices();
      toast.success(t("message.device_remove"));
    });
  }, [t, selectedDevices, hideModalConfirmation, getDevices]);

  const toggleCollapsedDevicesBlock = useCallback(
    (blockId: number) => {
      if (collapsedDevicesBlock.includes(blockId)) {
        setCollapsedDevicesBlock(
          collapsedDevicesBlock.filter((id) => id !== blockId)
        );
      } else {
        setCollapsedDevicesBlock([...collapsedDevicesBlock, blockId]);
      }
    },
    [collapsedDevicesBlock]
  );

  const filteredDevices = useMemo(
    () =>
      deviceType === "all"
        ? devices
        : devices.filter((device) => device.type === deviceType),
    [devices, deviceType]
  );

  const filteredLabDevices = useMemo(
    () =>
      deviceType === "all"
        ? labDevices
        : labDevices.map((lab) => ({
            ...lab,
            devices: lab.devices.filter((device) => device.type === deviceType),
          })),
    [labDevices, deviceType]
  );

  const menuItems = useMemo(
    () => [
      {
        title: t("common.all"),
        route: generatePath(ROUTES.DEVICES, { deviceType: "all" }),
      },
      {
        title: t("common.milling"),
        route: generatePath(ROUTES.DEVICES, { deviceType: "milling" }),
      },
      {
        title: t("common.furnace"),
        route: generatePath(ROUTES.DEVICES, { deviceType: "furnace" }),
      },
    ],
    [t]
  );

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setDevices((devices) => {
        const oldIndex = devices.findIndex(
          (device) => device.unitKey === active.id
        );
        const newIndex = devices.findIndex(
          (device) => device.unitKey === over?.id
        );
        return arrayMove(devices, oldIndex, newIndex);
      });
    }
  };

  const titleComponent = useCallback(
    (title: string, count: number, blockId: number) => (
      <div className="my-6 md:my-4 h-[64px] flex items-center justify-between border-b border-gray3 text-white">
        <div className="flex items-center">
          <span className="text-xl font-bold">{title}</span>

          <div className="ml-2 px-3 py-1 rounded-2xl border border-gray3">
            <span className="text-base font-normal">{count}</span>
          </div>
        </div>

        <div
          className={`cursor-pointer ${
            !collapsedDevicesBlock.includes(blockId) ? "rotate-180" : ""
          }`}
          onClick={() => toggleCollapsedDevicesBlock(blockId)}
        >
          <DropdownIcon size={16} />
        </div>
      </div>
    ),
    [collapsedDevicesBlock, toggleCollapsedDevicesBlock]
  );

  const devicesComponent = useMemo(
    () => (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <section className="pt-0 md:pt-0 sm:pt-0 p-8 md:p-6 sm:p-4 overflow-y-scroll">
          {titleComponent(t("common.my_devices"), filteredDevices.length, 0)}

          <SortableContext items={sortableItems}>
            {!collapsedDevicesBlock.includes(0) && (
              <div className="flex gap-4 flex-wrap">
                {filteredDevices.map((device) => (
                  <ItemDevice
                    key={device.unitKey}
                    device={device}
                    isActive={selectedDevices.includes(device.unitKey)}
                    isDraggable={!isMobile && isEditMode}
                    onClick={() => {
                      isEditMode
                        ? selectDevice(device.unitKey)
                        : toDeviceDetails(device);
                    }}
                  />
                ))}
              </div>
            )}
          </SortableContext>

          {!isEditMode &&
            filteredLabDevices.map(({ id, name, devices }) =>
              devices.length ? (
                <Fragment key={id}>
                  {titleComponent(name, devices.length, id)}

                  {!collapsedDevicesBlock.includes(id) && (
                    <div className="flex gap-4 flex-wrap">
                      {devices.map((device) => (
                        <ItemDevice
                          key={`${id} ${device.unitKey}`}
                          device={device}
                          onClick={() => toDeviceDetails(device)}
                        />
                      ))}
                    </div>
                  )}
                </Fragment>
              ) : null
            )}
        </section>
      </DndContext>
    ),
    [
      t,
      sensors,
      sortableItems,
      filteredLabDevices,
      filteredDevices,
      selectedDevices,
      isEditMode,
      collapsedDevicesBlock,
      titleComponent,
      selectDevice,
      toDeviceDetails,
    ]
  );

  return (
    <>
      <Header title={t("common.devices")}>
        <div className="flex sm:mt-4 sm:justify-end sm:basis-full">
          {isEditMode ? (
            <>
              <Button
                type="danger"
                disabled={!selectedDevices.length}
                IconBefore={CloseIcon}
                onClick={showModalConfirmation}
              >
                {t("common.remove")}
              </Button>
              <Button IconBefore={CheckIcon} onClick={disableEditMode}>
                {t("common.done")}
              </Button>
            </>
          ) : (
            <>
              <Button
                type="grayNoOffset"
                IconBefore={RefreshIcon}
                onClick={getDevices}
              >
                {t("common.refresh")}
              </Button>
              <Button
                type="gray"
                IconBefore={EditIcon}
                onClick={enableEditMode}
              >
                {t("common.edit")}
              </Button>
              <Button IconBefore={PlusIcon} onClick={showModalAddDevice}>
                {t("common.add")}
              </Button>
            </>
          )}
        </div>
      </Header>

      <HorizontalMenu menuItems={menuItems} />

      {isLoading ? (
        <Loading />
      ) : filteredDevices.length || filteredLabDevices.length ? (
        devicesComponent
      ) : (
        <Empty
          title={t("message.no_added_devices.0")}
          subtitle={t("message.no_added_devices.1")}
          Icon={NetworkIcon}
        />
      )}

      {isModalAddDevice && <ModalAddDevice hideModal={hideModalAddDevice} />}

      {isModalConfirmation && (
        <ModalConfirmation
          type="remove"
          title={t("common.remove_device")}
          text={t("common.modal_confirmation_device")}
          hideModal={hideModalConfirmation}
          onClick={removeSelectedDevices}
        />
      )}
    </>
  );
};
