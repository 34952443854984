import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { SelectIcon } from "icons/SelectIcon";
import { CheckIcon } from "icons/CheckIcon";

type Option = { value: number; label: string };

interface SelectProps {
  options: Option[];
  value: Option["value"] | null;
  setValue: (value: Option["value"]) => void;
}

export const Select: React.FC<SelectProps> = ({ options, value, setValue }) => {
  const [isSelectActive, setIsSelectActive] = useState(false);

  const { t } = useTranslation();

  const selectValue = useCallback(
    (value: Option["value"]) => {
      setValue(value);
      setIsSelectActive(false);
    },
    [setValue]
  );

  const activeValue = useMemo(
    () => options.find((option) => option.value === value)?.label,
    [options, value]
  );

  return (
    <div className="relative" onMouseLeave={() => setIsSelectActive(false)}>
      <div
        className="h-[40px] px-4 rounded-lg hover:opacity-70 transition-all duration-300 ease-in-out border border-gray4 bg-gray3 flex flex-1 items-center justify-between cursor-pointer text-white"
        onClick={() => setIsSelectActive(true)}
      >
        <span
          className={`font-normal text-base ${
            activeValue ? "capitalize" : "text-gray6"
          }`}
        >
          {activeValue || t("common.choose_option")}
        </span>

        <SelectIcon />
      </div>

      {isSelectActive && (
        <div className="pt-2 absolute left-0 right-0">
          <div className="border border-gray4 rounded-lg overflow-hidden">
            {options.map((option) => {
              const isActive = option.value === value;

              return (
                <div
                  key={option.value}
                  className={`h-[40px] px-4 flex flex-1 items-center justify-between cursor-pointer text-white ${
                    isActive ? "bg-green" : "bg-gray3 hover:bg-gray4"
                  }`}
                  onClick={() => selectValue(option.value)}
                >
                  <span className="font-normal text-base capitalize">
                    {option.label}
                  </span>

                  {isActive && <CheckIcon size={24} />}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
