import * as authApi from "api/auth";
import { serverInstance as api } from "api/instances";
import { LSAccessToken } from "utils/LocalStorage";

export * from "./invitation";
export * from "./join";
export * from "./lab";
export * from "./roles";
export * from "./group";
export * from "./user";
export * from "./users";
export * from "./machine";
export * from "./license";
export * from "./contacts";
export * from "./software";
export * from "./device";

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      const access_token = await authApi.refreshAccessToken();

      if (access_token) {
        setAccessToken(access_token);
        LSAccessToken.set(access_token);

        originalRequest._retry = true;
        originalRequest.headers["Authorization"] = access_token;
        return api(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

export const setAccessToken = (token: string) => {
  api.defaults.headers.common["Authorization"] = token;
};

export const clearAccessToken = () => {
  api.defaults.headers.common["Authorization"] = undefined;
};
