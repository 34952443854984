import React from "react";

interface BadgeProps {
  small?: boolean;
}

export const Badge: React.FC<BadgeProps> = ({ small }) => (
  <div
    className={`absolute top-[-4px] right-[-4px] aspect-square bg-red rounded-full border-2 border-black ${
      small ? "w-[10px]" : "w-[12px]"
    }`}
  />
);
