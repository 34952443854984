import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { SER } from "utils/serverErrorHandler";
import { getCurrentScreenData } from "utils/getCurrentScreenData";
import { Image } from "components/Image";
import { CircleProgressBar } from "components/CircleProgressBar";
import { Loading } from "components/Loading";
import { Empty } from "components/Empty";
import { NetworkIcon } from "icons/NetworkIcon";
import { Maintenance } from "types";

const contentSize = getCurrentScreenData({
  sm: { image: 50, icon: 26, circleProgressBar: 66 },
  all: { image: 80, icon: 36, circleProgressBar: 88 },
});

export const DeviceMaintenance: React.FC = () => {
  const [maintenances, setMaintenances] = useState<Maintenance[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { unitKey } = useParams();
  const { t } = useTranslation();

  const getMaintenance = useCallback(() => {
    if (unitKey) {
      SER(async () => {
        setIsLoading(true);

        const { data: maintenances } = await serverApi.getMaintenances([
          unitKey,
        ]);
        setMaintenances(maintenances);

        setIsLoading(false);
      });
    }
  }, [unitKey]);

  useEffect(() => {
    getMaintenance();
  }, [getMaintenance]);

  return isLoading ? (
    <Loading />
  ) : maintenances.length ? (
    <div className="flex flex-wrap pt-6 sm:p-4 gap-4 overflow-y-scroll">
      {maintenances.map((item) => (
        <div
          key={item.id}
          className="p-4 sm:p-2 bg-gray1 rounded-lg flex basis-[calc(50%-8px)] 2xl:basis-full overflow-hidden items-center justify-between"
        >
          <div className="flex items-center min-w-[calc(100%-88px)]">
            <div className="min-w-[120px] sm:min-w-[80px] aspect-square rounded-lg bg-gray3 flex items-center justify-center">
              <Image
                rounded="lg"
                size={contentSize.image}
                iconSize={contentSize.icon}
                src={item.imageUrl}
              />
            </div>

            <span
              title={item.message}
              className="mx-6 sm:mx-4 text-xl sm:text-lg font-bold text-white whitespace-nowrap text-ellipsis overflow-hidden"
            >
              {item.message}
            </span>
          </div>

          <div className="relative">
            <CircleProgressBar
              size={contentSize.circleProgressBar}
              strokeWidth={4}
              strokeColor={item.progressColor ?? "#FFFFFF"}
              progress={item.progress || 0}
            />

            <div className="absolute top-0 right-0 left-0 bottom-0 flex justify-center items-center">
              <span className="text-base font-semibold text-white">
                {item.progress.toFixed(0)}%
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <Empty title={t("common.no_maintenance_warning")} Icon={NetworkIcon} />
  );
};
