import { serverInstance as api } from "api/instances";
import { createUrlencoded } from "utils/createUrlencoded";
import { Id, Lab } from "types";

type LabData = {
  name: string;
  realm: string;
  country: string;
  city: string;
  street: string;
  street_number: string;
  postcode: string;
  phone: string;
  primary_email: string;
  backup_email: string;
  profile_picture?: string;
  members?: string[];
};

const URL = "lab";

export const getLabs = () => api.get<{ data: Lab[] }>(URL);

export const getLab = (labId: Id) =>
  api.get<{ data: Lab }>(`${URL}/id/${labId}`);

export const leaveLab = (labId: Id) => api.post(`${URL}/leave/${labId}`);

export const deactivateLab = (labId: Id) =>
  api.post(`${URL}/deactivate/${labId}`);

export const createLab = (data: LabData) =>
  api.request({
    method: "post",
    url: `${URL}/create`,
    data: createUrlencoded(data),
  });

export const updateLab = (labId: Id, data: LabData) =>
  api.request({
    method: "patch",
    url: `${URL}/${labId}`,
    data: createUrlencoded(data),
  });
