import React, { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { useAuthProvider } from "provider";
import { ROUTES } from "constants/routes";
import { SER } from "utils/serverErrorHandler";
import { getCurrentScreenData } from "utils/getCurrentScreenData";
import { getProfilePicture } from "utils/getProfilePicture";
import { ModalManageLab } from "components/Modal/ModalManageLab";
import { ModalJoinLab } from "components/Modal/ModalJoinLab";
import { Button } from "components/Button";
import { Header } from "components/Header";
import { Image } from "components/Image";
import { Loading } from "components/Loading";
import { MemberList } from "components/MemberList";
import { Lab } from "types";

const contentSize = getCurrentScreenData({
  sm: { image: 120, icon: 40 },
  md: { image: 140, icon: 40 },
  all: { image: 196, icon: 50 },
});

export const Laboratories: React.FC = () => {
  const [labs, setLabs] = useState<Lab[]>([]);
  const [isModalManageLab, setIsModalManageLab] = useState(false);
  const [isModalJoinLab, setIsModalJoinLab] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user } = useAuthProvider();

  const getLabs = useCallback(() => {
    SER(async () => {
      setIsLoading(true);

      const { data: labs } = await serverApi.getLabs();

      if (!user?.multilab && labs.data.length === 1) {
        toLaboratoryDetails(labs.data[0]);
      } else {
        setLabs(labs.data);

        const labsWithUsers = await Promise.all(
          labs.data.map(async (lab) => {
            const { data: users } = await serverApi.getLabUsers(lab.id);
            return { ...lab, users: users.data };
          })
        );

        setLabs(labsWithUsers);
      }

      setIsLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLabs();
  }, [getLabs]);

  const showModalManageLab = useCallback(() => {
    setIsModalManageLab(true);
  }, []);

  const hideModalManageLab = useCallback(() => {
    setIsModalManageLab(false);
    getLabs();
  }, [getLabs]);

  const showModalJoinLab = useCallback(() => {
    setIsModalJoinLab(true);
  }, []);

  const hideModalJoinLab = useCallback(() => {
    setIsModalJoinLab(false);
  }, []);

  const toLaboratoryDetails = useCallback(
    (lab: Lab) => {
      navigate(
        generatePath(ROUTES.LABORATORY_MEMBERS, { labId: lab.id.toString() }),
        { state: lab }
      );
    },
    [navigate]
  );

  const labsComponent = useMemo(
    () => (
      <section className="overflow-y-scroll px-8 pt-6 md:px-6 md:pt-4 sm:px-4 sm:pt-0">
        {labs.map((lab) => (
          <div
            key={lab.id}
            className="pr-8 lg:pr-6 md:pr-4 mb-6 border border-gray3 bg-gray1 rounded-lg flex items-center justify-between overflow-hidden sm:mb-4 cursor-pointer"
            onClick={() => toLaboratoryDetails(lab)}
          >
            <div className="flex items-center md:flex-1">
              <Image
                size={contentSize.image}
                iconSize={contentSize.icon}
                src={getProfilePicture(lab)}
              />

              <div className="ml-8 md:ml-6 sm:ml-4 md:flex md:flex-1 md:justify-between">
                <div>
                  <h1 className="text-white font-bold text-3xl md:text-xl">
                    {lab.name}
                  </h1>
                  <span className="block mt-1 text-gray6 font-normal text-xl md:text-base">
                    {lab.realm}
                  </span>
                </div>

                <div className="ml-2 mt-6 md:mt-3 sm:hidden">
                  <MemberList users={lab.users} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    ),
    [labs, toLaboratoryDetails]
  );

  const buttons = useMemo(
    () => (
      <>
        <Button type="grayNoOffset" onClick={showModalJoinLab}>
          {t("common.join_lab")}
        </Button>
        <Button onClick={showModalManageLab}>{t("common.create_lab")}</Button>
      </>
    ),
    [t, showModalJoinLab, showModalManageLab]
  );

  return (
    <>
      <Header
        title={
          Boolean(user?.multilab)
            ? t("common.laboratories")
            : t("common.laboratory")
        }
      >
        {labs.length ? (
          <div className="flex sm:mt-4 sm:justify-between sm:basis-full">
            {buttons}
          </div>
        ) : null}
      </Header>

      {isLoading ? (
        <Loading />
      ) : labs.length ? (
        labsComponent
      ) : (
        <div className="px-8 py-6 md:px-6 md:py-4 sm:px-4 flex flex-1">
          <div className="bg-[#0D0D0D] border border-dashed border-[rgba(255,255,255,0.50)] rounded-lg flex flex-1 flex-col items-center justify-center">
            <h2 className="text-base font-bold text-white">
              {t("common.lets_get_started")}
            </h2>
            <span className="h-[40px] text-base font-bold text-white opacity-50">
              ...
            </span>

            <div className="flex">{buttons}</div>
          </div>
        </div>
      )}

      {isModalManageLab && <ModalManageLab hideModal={hideModalManageLab} />}

      {isModalJoinLab && <ModalJoinLab hideModal={hideModalJoinLab} />}
    </>
  );
};
