import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { serverApi } from "api";
import { useMenuActions } from "hooks/useMenuActions";
import { ROUTES } from "constants/routes";
import { SER } from "utils/serverErrorHandler";
import { ModalConfirmation } from "components/Modal/ModalConfirmation";
import { ModalManageLab } from "components/Modal/ModalManageLab";
import { DropdownMenu } from "components/DropdownMenu/DropdownMenu";
import { ThreeDotsButton } from "components/ThreeDotsButton";
import { ItemLab } from "components/Item/ItemLab";
import { Permissions, Lab } from "types";

interface DropdownMenuLabProps {
  lab: Lab;
  permissions: Permissions;
  manageLabCB: () => void;
}

export const DropdownMenuLab: React.FC<DropdownMenuLabProps> = ({
  lab,
  permissions,
  manageLabCB,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { MENU_ACTIONS } = useMenuActions();

  const [isModalManageLab, setIsModalManageLab] = useState(false);
  const [isModalConfirmationDelete, setIsModalConfirmationDelete] =
    useState(false);
  const [isModalConfirmationLeave, setIsModalConfirmationLeave] =
    useState(false);

  const showModalManageLab = useCallback(() => {
    setIsModalManageLab(true);
  }, []);

  const hideModalManageLab = useCallback(() => {
    setIsModalManageLab(false);
    manageLabCB();
  }, [manageLabCB]);

  const showModalConfirmationDelete = useCallback(() => {
    setIsModalConfirmationDelete(true);
  }, []);

  const hideModalConfirmationDelete = useCallback(() => {
    setIsModalConfirmationDelete(false);
  }, []);

  const showModalConfirmationLeave = useCallback(() => {
    setIsModalConfirmationLeave(true);
  }, []);

  const hideModalConfirmationLeave = useCallback(() => {
    setIsModalConfirmationLeave(false);
  }, []);

  const leaveLab = useCallback(() => {
    SER(async () => {
      await serverApi.leaveLab(lab.id);
      hideModalConfirmationLeave();
      navigate(ROUTES.LABORATORIES);
      toast.success(t("message.leave_lab_success"));
    });
  }, [t, lab, hideModalConfirmationLeave, navigate]);

  const deactivateLab = useCallback(() => {
    SER(async () => {
      await serverApi.deactivateLab(lab.id);
      hideModalConfirmationDelete();
      navigate(ROUTES.LABORATORIES);
      toast.success(t("message.delete_lab_success"));
    });
  }, [t, lab, hideModalConfirmationDelete, navigate]);

  const menuOptions = useMemo(() => {
    const options = [MENU_ACTIONS.leaveLaboratory(showModalConfirmationLeave)];

    if (permissions.laboratory) {
      options.unshift(MENU_ACTIONS.editLaboratory(showModalManageLab));
      options.push(MENU_ACTIONS.deleteLaboratory(showModalConfirmationDelete));
    }

    return options;
  }, [
    MENU_ACTIONS,
    permissions,
    showModalManageLab,
    showModalConfirmationLeave,
    showModalConfirmationDelete,
  ]);

  return (
    <>
      <DropdownMenu options={menuOptions}>
        <ThreeDotsButton />
      </DropdownMenu>

      {isModalConfirmationDelete && (
        <ModalConfirmation
          type="delete"
          title={t("common.delete_laboratory")}
          text={t("common.modal_confirmation_lab_delete")}
          hideModal={hideModalConfirmationDelete}
          onClick={deactivateLab}
        >
          <ItemLab lab={lab} />
        </ModalConfirmation>
      )}

      {isModalConfirmationLeave && (
        <ModalConfirmation
          type="leave"
          title={t("common.leave_laboratory")}
          text={t("common.modal_confirmation_lab_leave")}
          hideModal={hideModalConfirmationLeave}
          onClick={leaveLab}
        >
          <ItemLab lab={lab} />
        </ModalConfirmation>
      )}

      {isModalManageLab && (
        <ModalManageLab lab={lab} hideModal={hideModalManageLab} />
      )}
    </>
  );
};
